<template>
  <div class="labelled-input" :class="'labelled-input-' + type">
    <label>
      <div v-if="this.$slots.default" class="labelled-input__title">
        <slot></slot>
      </div>
      <input
        :style="{ ...style }"
        :type="type"
        :name="name"
        :value="modelValue"
        :checked="modelValue"
        :class="classObject"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="onChange"
        @focus="focusEvent"
        @blur="blurEvent"
      />
    </label>
    <aside v-if="hasError" class="labelled-input__errors">
      <ul>
        <li v-for="error in validator.$errors">{{ error.$message }}</li>
      </ul>
    </aside>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LabelledInput",
  props: {
    type: {
      type: String,
      required: false,
      default: "text",
    },
    modelValue: {
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    validator: {
      required: false,
      type: Object,
      default: null,
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    style: {
      required: false,
      type: Object,
      default: {},
    },
    disabled: {
      required: false,
      type: [Boolean, Number],
      default: false,
    },
  },

  computed: {
    classObject() {
      return {
        "-pristine": this.validator && !this.validator.$dirty,
        "-error": this.validator && this.validator.$dirty && this.hasError,
        "-correct": this.validator && this.validator.$dirty && !this.hasError,
      };
    },

    hasError() {
      return this.validator && this.validator.$dirty && this.validator.$errors.length > 0;
    },
  },

  methods: {
    onChange(event) {
      this.emitNewValue(event);

      this.validator && this.validator.$validate();
    },

    emitNewValue(event) {
      let emittedValue = null;
      switch (this.type) {
        case "checkbox":
          emittedValue = event.target.checked;
          break;
        default:
          emittedValue = event.target.value;
      }

      this.$emit("changed", emittedValue);
      this.$emit("update:modelValue", emittedValue);
    },

    focusEvent() {
      this.$emit("focus");
    },

    blurEvent() {
      this.$emit("blur");
    },
  },
});
</script>
